import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Cyber security cannot be an afterthought
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Let us guide you and avoid common pitfalls with our incident prevention services
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">Get in touch</Button>
          </p>
          <p className="mt-4 text-gray-600">Get in touch to create a custom fit audit</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Our Services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">General Incident Prevention</p>
              <p className="mt-4">
                Our consulting team will review your cyber footprint and help you avoid common pitfalls that cause the most common incidents.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Crypto Threat Analysis</p>
              <p className="mt-4">
                Let our expert analysts guide you through the dangerous crypto landscape and identify the biggest threats in your projects.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Custom Audits</p>
              <p className="mt-4">
                Our team of experts can define a plan that fits best based on your company's needs. We can work with any industry and in any jurisdiction.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to start?</h3>
      <p className="mt-8 text-xl font-light">
        Reach out and let's have a conversation on how to protect your company from cyber threats
      </p>
      <p className="mt-8">
        <Button size="xl">Get in touch</Button>
      </p>
    </section>
  </Layout>
);

export default Index;
