export default [
  {
    title: 'Best audit experience',
    content:
      'The entire team was focused on getting through our audit smoothly.',
    customerName: 'Enrique Sanchez',
    customerTitle: 'President'
  },
  {
    title: 'Threat assessment',
    content:
      'Completed a full 360 degree in 30 days and discovered critical issues.',
    customerName: 'Jeff Rogers',
    customerTitle: 'Head of Security'
  },
  {
    title: 'Customer Service',
    content:
      'The best part of our engagement was the focus on great customer service.',
    customerName: 'Xavier Perez',
    customerTitle: 'Chairman and CEO'
  }
];
